<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'אני רוצה את הטבלה המלאה!'"
                         :next-button-props="{ name: $options.name }" :padding="'20px 40px 0'">
    <div class="calculator-container">
      <div class="title">בּוֹאוּ נְשַׂחֵק קְצָת 🧮</div>
      <div>קיבלתי במתנה מ{{ parent }} {{ interestAmount }}% ריבית על הכסף שיש לי בקופה.</div>
      <div>אם יש לי בקופה:</div>
      <dropdown-input :options="availableMoneyAmounts"
                      :first-selected="currentMoney"
                      @select="setAmount"></dropdown-input>
      <div>תגלו לי כמה כסף יהיה לי ב:</div>
      <dropdown-input :options="availableInterestPoints"
                      :first-selected="yearsAhead"
                      @select="setYearsAhead"></dropdown-input>
      <div class="amount">
        <div class="number-container">
          <animated-number class="number" :value="calculatedAmount" :formatter="format"/>
        </div>
        <div :style="circleCss" class="circle"></div>
      </div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import DropdownInput from '@/base-components/DropdownInput';
import money from '@/utils/money';
import utils from '../pocket-money-utils';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Parents',
  components: {
    DropdownInput,
    AnimatedNumber: BaseUI.AnimatedNumber,
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  data() {
    return {
      yearsAhead: 0,
      amount: this.currentMoney,
    };
  },
  computed: {
    ...mapState('pocketMoney', ['interestAmount', 'currentMoney', 'childGrade', 'parent']),
    availableInterestPoints() {
      const nextGrades = utils.grades.filter(grade => grade.value > this.childGrade);
      return [
        { label: 'סוף שנת הלימודים הזו', value: 0 },
        ...nextGrades.map(({ label }, index) => {
          return { label: `סוף ${label}`, value: index + 1 };
        }),
      ];
    },
    availableMoneyAmounts() {
      return [
        { label: `${this.currentMoney} ₪`, value: this.currentMoney },
        ...[25, 50, 100, 150, 200, 250, 350].map(amount => {
          return { label: `${this.currentMoney + amount} ₪`, value: roundToNearest10(this.currentMoney) + amount };
        }),
      ];
    },
    calculatedAmount() {
      return utils.getEndOfYearAmount(this.amount || this.currentMoney, this.interestAmount, this.yearsAhead);
    },
    circleCss() {
      const maxAmount = _.last(this.availableMoneyAmounts).value;
      const maxYears = _.last(this.availableInterestPoints).value;
      const maxValue = utils.getEndOfYearAmount(maxAmount, this.interestAmount, maxYears);

      const minAmount = _.first(this.availableMoneyAmounts).value;
      const minYears = _.first(this.availableInterestPoints).value;
      const minValue = utils.getEndOfYearAmount(minAmount, this.interestAmount, minYears);

      // pixels should be between 100-200 to be in safe range
      const scale = _.max([((this.calculatedAmount - minValue) / (maxValue - minValue)), 0]);
      const pixels = (Math.sqrt(scale) * 100) + 50;
      return {
        height: `${pixels}px`,
        width: `${pixels * 2}px`,
        'border-radius': `${pixels}px ${pixels}px 0 0`,
        left: `calc(50% - ${pixels}px)`,
      };
    },
  },
  methods: {
    setYearsAhead(value) {
      this.yearsAhead = value;
    },
    setAmount(value) {
      this.amount = value;
    },
    format(value) {
      return `${money.formatAmountWithFraction(value, 0)} ₪`;
    },
  },
};

function roundToNearest10(number) {
  return Math.floor(number / 10) * 10;
}

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.calculator-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  .button {
    width: 100%;
  }
  .title {
    font-size: 32px;
    font-family: PauzaBold;
  }
  .amount {
    position: relative;
    width: 100%;
    flex-grow: 1;
    .number-container {
      margin-top: 30px;
      font-size: 48px;
      text-align: center;
      z-index: 1;
      position: absolute;
      bottom: 100px;
      width: 100%;
      text-align: center;
      .number {
      }
    }
    .circle {
      z-index: 0;
      position: absolute;
      background-color: $riseup_yellow;
      bottom: 0;
      transition: all 0.5s;
    }
  }
}

</style>
