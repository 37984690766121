<template>
  <pocket-money-template :page-index="pageIndex" :next-button-props="{ name: $options.name, childGrade, currentMoney }" :can-continue="canContinue">
    <div class="select-money">
      <div class="title">שלום {{ childName }},</div>
      <div>קיבלת מתנה מ{{ parent }} שלך! 🥳</div>
      <div>כדי לבדוק כמה המתנה שווה קודם כל אני צריך לדעת כמה כסף יש לך בקופה? 💰</div>
      <text-input placeholder="כמה כסף יש לך?" :value="`${currentMoney}`" :on-input="setMoney" label-text="" input-type="number"></text-input>
      <div>ובאיזה כיתה?</div>
      <dropdown-input :options="childGradeOptions"
                      :first-selected="childGrade"
                      @select="setGrade"></dropdown-input>
    </div>
  </pocket-money-template>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import DropdownInput from '@/base-components/DropdownInput';
import { mapActions, mapState } from 'vuex';
import utils from '../pocket-money-utils';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Money',
  components: {
    TextInput: BaseUI.TextInput,
    DropdownInput,
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['childName', 'parent', 'currentMoney', 'childGrade']),
    childGradeOptions() {
      return utils.grades;
    },
    canContinue() {
      return !!this.childGrade;
    },
  },
  methods: {
    ...mapActions('pocketMoney', ['set']),
    setMoney(value) {
      this.set({ prop: 'currentMoney', value: +value });
    },
    setGrade(value) {
      this.set({ prop: 'childGrade', value });
    },
  },
};

</script>

<style scoped lang="scss">

.select-money {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    font-size: 28px;
    font-family: PauzaBold;
  }
}

</style>
