<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'אפשר לשחק עם המספרים בעצמי?'" :next-button-props="{ name: $options.name }">
    <div class="select-parent">
      <img class="graph" src="/images/celebration/turnover.svg"/>
      <div class="title">{{ moneyInYears(3) }} ₪</div>
      <div>שזה כבר די הרבה. והכסף הזה רק ילך ויגדל לנצח! (או לפחות עד כיתה י״ב)</div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapState } from 'vuex';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'ThirdYear',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['interestAmount', 'currentMoney']),
  },
  methods: {
    moneyInYears(days) {
      const interestPerDay = this.interestAmount / 100 / 365;
      return Math.floor((+this.currentMoney) * ((1 + interestPerDay) ** (days * 365)));
    },
  },
};

</script>

<style scoped lang="scss">

.select-parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .button {
    width: 100%;
  }
  .title {
    margin: 20px 0 10px;
    font-size: 64px;
    font-family: PauzaBold;
    text-align: center;
  }
}

</style>
