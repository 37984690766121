<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'הבא'" :next-button-props="{ name: $options.name }"
                         :can-continue="canContinue" @next="sendInfoEvent">
    <div class="select-parent">
      <div class="title">היי {{ parentFirstName }},</div>
      <div>כדי שנוכל לשלוח לך את החוויה המלאה בהמשך, נשמח להצטרפות שלך לקהילה!</div>

      <email-input
        :placeholder="'אימייל'"
        :value="email"
        :onInput="setEmail"
        :inputType="'email'"
        :error="emailError"
        :onError="setEmailError"/>

      <text-input
        :autofocus="false"
        :placeholder="'טלפון נייד'"
        :value="phoneNumber"
        :onInput="setPhoneNumber"
        :inputType="'number'"/>
      <check-box :initially-checked="enableMarketingEmails"
                 :toggle="toggleEnableMarketingEmails">
        <template v-slot:title>
          <div class="checkbox-text">
            יש לרייזאפ אישור לשלוח לי תוכן ומידע נוסף
          </div>
        </template>
      </check-box>
    </div>
  </pocket-money-template>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import PocketMoneyApi from '@/api/PocketMoneyApi';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Parents',
  components: {
    TextInput: BaseUI.TextInput,
    CheckBox: BaseUI.CheckBox,
    EmailInput: BaseUI.EmailInput,
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  data() {
    return {
      enableMarketingEmails: true,
      phoneNumber: '',
      email: '',
      emailError: false,
    };
  },
  computed: {
    ...mapState('pocketMoney', ['parentFirstName', 'parentFamilyName', 'parent', 'childName', 'childGrade']),
    isPhoneNumberValid() {
      return this.phoneNumber.length >= 10;
    },
    isEmailValid() {
      return !this.emailError;
    },
    canContinue() {
      return this.isPhoneNumberValid && this.isEmailValid;
    },
  },
  methods: {
    ...mapActions('pocketMoney', ['set']),
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    setEmail(value) {
      this.email = value;
    },
    setEmailError(value) {
      this.emailError = value;
    },
    toggleEnableMarketingEmails() {
      this.enableMarketingEmails = !this.enableMarketingEmails;
    },
    sendInfoEvent() {
      PocketMoneyApi.sendEvent({
        firstName: this.parentFirstName,
        lastName: this.parentFamilyName,
        parent: this.parent,
        childName: this.childName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        enableMarketingEmails: this.enableMarketingEmails,
      });
    },
  },
};

</script>

<style scoped lang="scss">

.select-parent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    font-size: 28px;
    font-family: PauzaBold;
  }
  .checkbox-text {
    font-size: 14px;
    padding-right: 10px;
  }
}

</style>
