<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'תגלו לי!'" :next-button-props="{ name: $options.name }">
    <div class="select-parent">
      <img src="/images/celebration/lessnegative.svg"/>
      <div class="title">{{ Math.round(endOfCurrentSchoolYear) }} ₪</div>
      <div>וזה רק בסוף השנה הזו! יש לך מושג כמה כסף יהיה לך בסוף {{ name3Years }}'?</div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import pocketMoneyUtils from '../pocket-money-utils';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'FirstYear',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['childGrade']),
    ...mapGetters('pocketMoney', ['endOfCurrentSchoolYear']),
    name3Years() {
      return pocketMoneyUtils.gradeToString(this.childGrade + 3);
    },
  },
};

</script>

<style scoped lang="scss">

.select-parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .button {
    width: 100%;
  }
  .title {
    margin: 20px 0 10px;
    font-size: 64px;
    font-family: PauzaBold;
    text-align: center;
  }
}

</style>
