import { client } from '../http';

async function answerQuestion(question) {
  const response = await client.post('/api/answer-question', { question });
  return response.data;
}

async function feedbackAnswer({ question, isPositiveFeedback, textualFeedback }) {
  const response = await client.post('/api/feedback-question', { question, isPositiveFeedback, textualFeedback });
  return response.data;
}

export default {
  answerQuestion,
  feedbackAnswer,
};
