<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'תגלו לי!'" :next-button-props="{ name: $options.name }">
    <div class="select-parent">
      <div class="title">מעכשיו הכסף שלך יגדל! 💰</div>
      <div>כל יום ויום שיעבור ושמרת את הכסף, הכסף שלך יהיה שווה יותר. בהתחלה, הכסף יגדל <span class="bold">כל {{ timeFrame }}</span>
        ב-{{ timeAmount }} אגורות.</div>
      <img class="present" :src="require('@/assets/icons/present.svg')"/>
      <div>בניחוש, כמה הכסף שלך יהיה שווה בסוף שנת הלימודים הזו?</div>
    </div>
  </pocket-money-template>
</template>

<script>

import { mapGetters } from 'vuex';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Prediction',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('pocketMoney', ['dailyAmount', 'weeklyAmount']),
    timeFrame() {
      if (this.dailyAmount * 100 === 0) {
        return 'שבוע';
      }
      return 'יום';
    },
    timeAmount() {
      return this.dailyAmount * 100 || this.weeklyAmount * 100;
    },
  },
};

</script>

<style scoped lang="scss">

.select-parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .button {
    width: 100%;
  }
  .present {
    height: 100px;
  }
  .title {
    font-size: 32px;
    font-family: PauzaBold;
  }
  .bold {
    font-weight: bold;
  }
}

</style>
