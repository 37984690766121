<template>
  <div class="cashflow-assistant-container">
    <text-area :autofocus="true" :value="question" :on-input="setQuestion" placeholder="כמה הוצאתי על..."/>
    <riseup-button @click="sendToAssistant"
                   :disabled="loadingAnswer"
                   :variant="'primary'"
                   :size="'slim'"
                   title="שליחה"/>
    <Loader v-if="loadingAnswer"/>

    <dropdown-input @select="setQuestion"
                    :options="possibleQuestions"
                    :first-selected="null"
                    label="בחירת שאלה לדוגמא"></dropdown-input>
    <template v-if="answer">
      <div class="answer">{{ answer }}</div>
      <div class="transaction-list" v-if="transactions">
        <transaction-row-base v-for="(t, index) of transactions" :key="index"
                              :transaction-description="t.businessName"
                              :transaction-date="t.transactionDate"
                              :transaction="t"
                              :is-editable="false"
                              :actual-amount="t.amount">
          <template v-slot:on-transaction-label>
            <div class="category-label">{{ t.category }} / {{ t.subcategory }}</div>
          </template>
        </transaction-row-base>
      </div>
      <div class="feedback-container" v-if="!feedbackSubmitted">
        <div class="thumbs-prompt">האם אהבת את התשובה?</div>
        <text-area :value="textualFeedback" :on-input="setTextualFeedback" placeholder="אהבתי את התשובה כי..."/>
        <div class="thumbs">
          <div class="thumbs-box" @click="isPositiveFeedback = true" :class="{ selected: isPositiveFeedback }">
            <div class="text">כן!</div>
            <img class="image" src="/images/drawings/coin-happy.png" alt="">
          </div>
          <div class="thumbs-box" @click="isPositiveFeedback = false" :class="{ selected: isPositiveFeedback === false }">
            <div class="text">לא</div>
            <img class="image" src="/images/drawings/coin-stressed.png" alt="">
          </div>
        </div>
        <riseup-button @click="sendFeedback"
                       :disabled="isPositiveFeedback === null"
                       :variant="'secondary'"
                       :size="'slim'"
                       title="שליחת משוב"/>
      </div>
      <div v-else class="thanks-for-answering">תודה! 🙏</div>

      <div class="full-answer">
        <div class="expand-line" @click="toggleExtraFields">
          <div>פרטי התשובה</div>
          <img class="arrow" :class="{ open: showExtraFields }" :src="require('@/assets/icons/arrow-down.svg')">
        </div>
        <collapse-transition>
          <div v-if="showExtraFields" class="answer-details">
            <pre>subcategories: {{ fullAnswer.subcategories }}</pre>
            <pre>confidence: {{ fullAnswer.confidence }}</pre>
            <pre>{{ fullAnswer.code }}</pre>
          </div>
        </collapse-transition>
      </div>

    </template>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Loader from '@/base-components/Loader';
import DropdownInput from '@/base-components/DropdownInput.vue';
import CashflowAssistantApi from '@/api/CashflowAssistantApi';
// eslint-disable-next-line max-len
import TransactionRowBase from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/TransactionRowBase.vue';

export default {
  name: 'CashflowAssistant',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    CollapseTransition: BaseUI.CollapseTransition,
    TextArea: BaseUI.TextArea,
    DropdownInput,
    Loader,
    TransactionRowBase,
  },
  data() {
    return {
      question: '',
      loadingAnswer: false,
      answer: '',
      transactions: [],
      showExtraFields: false,
      fullAnswer: null,
      isPositiveFeedback: null,
      textualFeedback: '',
      feedbackSubmitted: false,
    };
  },
  computed: {
    possibleQuestions() {
      return [
        'מהם שלושת בתי הקפה שהוצאתי בהם הכי הרבה כסף?',
        'מתי אני מוציא יותר כסף על אוכל בחוץ, באמצע השבוע או בסופ״ש?',
        'מהו החודש בו היו לי הכי מעט הוצאות?',
        'כמה הוצאתי בסופרים קטנים לעומת סופרים גדולים בפברואר?',
        'כמה הוצאתי על רכב בשנה האחרונה, כולל דלק טיפולים וביטוח?',
        'כמה כסף העברתי בביט ב-2023?',
        'אם אמשיך באותו קצב, האם אוציא החודש יותר על אוכל בחוץ או פחות בהשוואה למרץ?',
        'כמה כסף חסכתי במרץ?',
        'מתי בפעם האחרונה הייתי במוסך?',
        'כמה כסף הוצאתי על חניה בחצי השנה האחרונה?',
        'באיזו מסעדה אכלתי הכי הרבה פעמים?',
        'מהן שלוש הארוחות היקרות ביותר שהיו לי במסעדה?',
        'כמה הוצאתי על דלק בתחנות פז לעומת תחנות אחרות?',
        'מהו החודש בו היו לי הכי מעט הכנסות משתנות החל מינואר 2023?',
        'מהן ההוצאות הקבועות שלי שעלו מפברואר למרץ ביותר מ-20%?',
        'כמה הוצאות מתחת ל-30 שקלים היה לי השנה, מתי הן קורות ומה הסכום הכולל שלהן?',
        'כמה כסף שילמתי על ביטוח חיים ב-2023?',
        'באיזה ימים בחודש אני נוטה להוציא הכי הרבה כסף?',
        'איפה אני מוציא יותר כסף, ברמי לוי או בשופרסל?',
      ].map(t => {
        return { label: t, value: t };
      });
    },
  },
  methods: {
    setQuestion(question) {
      this.question = question;
      this.clearFeedback();
      this.clearAnswerFields();
    },
    setTextualFeedback(textualFeedback) {
      this.textualFeedback = textualFeedback;
    },
    toggleExtraFields() {
      this.showExtraFields = !this.showExtraFields;
    },
    async sendToAssistant() {
      this.loadingAnswer = true;
      const {
        nl_response: answer, transactions_list: transactions, code, subcategories, confidence,
      } = await CashflowAssistantApi.answerQuestion(this.question);
      this.answer = answer;
      this.transactions = transactions;
      this.fullAnswer = { answer, transactions, code, subcategories, confidence };
      this.clearFeedback();
      this.loadingAnswer = false;
    },
    clearFeedback() {
      this.isPositiveFeedback = null;
      this.feedbackSubmitted = false;
      this.textualFeedback = '';
    },
    clearAnswerFields() {
      this.fullAnswer = null;
      this.answer = null;
      this.transactions = [];
    },
    async sendFeedback() {
      this.loadingAnswer = true;
      await CashflowAssistantApi.feedbackAnswer({
        question: this.question,
        isPositiveFeedback: this.isPositiveFeedback,
        textualFeedback: this.textualFeedback,
      });
      this.feedbackSubmitted = true;
      this.loadingAnswer = false;
    },
  },
};

</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.cashflow-assistant-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .answer {
    font-size: 16px;
    padding: 14px;
    border-radius: 6px;
    line-height: 24px;
    background-color: $riseup_light_yellow;
  }
  .answer-details {
    direction: ltr;
  }
  .full-answer {
    padding: 14px;
    border-radius: 6px;
    background-color: $riseup_light_yellow;
    .expand-line {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .answer-details {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .arrow {
    width: 13px;
    transition: all 0.3s;
    &.open {
      transform: rotate(180deg);
    }
  }
  .category-label {
    font-size: 12px;
    background-color: $riseup_blue;
    color: $riseup_white;
    padding: 2px 4px;
    border-radius: 4px;
    text-align: center;
  }
  .thumbs-prompt {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
    text-align: center;
  }
  .thumbs {
    display: flex;
    gap: 20px;
    .thumbs-box {
      flex-grow: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: solid 1px $riseup_gray_20;
      cursor: pointer;
      &.selected {
        border: solid 4px $riseup_blue;
      }
      .image {
        width: 55%;
      }
      .text {
        width: 30%;
        font-weight: bold;
        margin-right: 14px;
      }
    }
  }
  .feedback-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .thanks-for-answering {
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>

<style lang="scss">
.cashflow-assistant-container {
  .dropdown-input-container {
    .dropdown-input {
      font-size: 14px;

      .vs__dropdown-toggle {
        height: 100%;
      }
    }
    .vs__dropdown-menu {
      .vs__dropdown-option {
        font-size: 14px;
      }
    }
  }
}
</style>
