<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'לטבלה המלאה'"
                         :next-button-props="{ name: $options.name }" :is-last="true">
    <div class="done">
      <div class="title">היי {{ parentFirstName }},</div>
      <div>מקווים שנהנת עם {{ childName }}! המשחק הוא רק ההתחלה :)
        אם תבחרו את השיטה הזאת לדמי כיס, הכנו במיוחד טבלה קלילה ונוחה כדי לעקוב ברמה השבועית על דמי הכיס של {{ childName }}.</div>
      <div>הטבלה ועוד מלא תוכן על כסף נמצאים בקבוצת הפייסבוק שלנו, נשמח לראות אותך שם!</div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapState } from 'vuex';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Thanks',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['parentFirstName', 'childName']),
  },
};

</script>

<style scoped lang="scss">

.done {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    font-size: 36px;
    font-family: PauzaBold;
    margin-top: 12px;
  }
}

</style>
