<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'אני רוצה להתחיל!'"
                         :is-first="true" :next-button-props="{ name: $options.name }">
    <div class="welcome">
      <img class="image" src="/images/celebration/lessnegative.svg"/>
      <div class="title">מחשבון דמי הכיס של RiseUp</div>
      <div>אנחנו קהילת רייזאפ!
        המשחק החינמי נועד לתת להורים דרך להסביר לילדים וילדות שיטה חדשה לדמי כיס 💫
        שיטה שתעודד אותם לתת לכסף שלהם לעשות עוד כסף!
      </div>
      <div>לזה אנחנו קוראים חינוך פיננסי 💪</div>
    </div>
  </pocket-money-template>
</template>

<script>
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Welcome',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
};

</script>

<style scoped lang="scss">

.welcome {
  display: flex;
  flex-direction: column;
  gap: 28px;
  .image {
    height: 150px;
  }
  .title {
    font-size: 28px;
    text-align: center;
    font-family: PauzaBold;
  }
}

</style>
