<template>
  <div class="personal-information-container">
    <div class="header">פרטים אישיים</div>
    <div class="personal-information-section">
      <div class="question-text disabled">שם מלא</div>
      <text-input :value="`${activeMember.firstNamePiiValue} ${activeMember.lastNamePiiValue}`" :disabled="true"/>

      <div class="question-text disabled">אימייל</div>
      <email-input
        :value="email"
        :onInput="setEmail"
        :onError="setEmailError"/>

      <div class="question-text disabled">טלפון</div>
      <text-input :value="formatPhoneNumber(activeMember.phoneNumberPiiValue)" :disabled="true"/>

      <div class="contact-us">
        <span class="icon">
          <img src="/images/info-icon-blue.svg"/>
        </span>
        <span class="cta">יש טעות בפרטים?</span>
        <span class="link" @click="openChat">צרו איתנו קשר</span>
      </div>
    </div>

    <div class="demographic-info-header">
      <div class="text">
        <div class="title">״איך אני ביחס ללקוחות שדומים לי?״</div>
        <div class="subtitle">כמה פרטים יעזרו לנו להציג לך עוד תובנות על ההוצאות שלך</div>
      </div>
      <div class="icon">
        <img class="sun" :src="require('@/assets/sun3.svg')">
      </div>
    </div>

    <div class="demographic-info-section">
      <autocomplete-input placeholder="מקום מגורים נוכחי" :options="cityNames" @change="setCity" :start-value="city" label="מקום מגורים"/>

      <dropdown-input @select="setMaritalStatus"
                      :options="maritalStatusAnswers"
                      :first-selected="maritalStatus"
                      label="מצב משפחתי"
                      placeholder="מה מצבך המשפחתי?"></dropdown-input>

      <dropdown-input @select="setHasKidsInputValue"
                      :options="hasKidsAnswers"
                      :first-selected="hasKidsInputValue"
                      label="האם יש ילדים בבית?"
                      placeholder="האם יש ילדים בבית?"></dropdown-input>
      <div v-if="hasKidsInputValue === HAS_KIDS">
        <div class="question-text">מספר ילדים</div>
        <text-input placeholder="כמה ילדים יש בבית?" :value="`${numberOfKids}`" :onInput="setKids"
                    inputType="number" :inputPattern="'\\d*'"/>
      </div>

      <riseup-button class="save-button" :disabled="disabled" :title="'שמירת פרטים'" :action="save" :loading="isSaving"/>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import _ from 'lodash';
import EventBus from '@/event-bus/event-bus';
import DetailsUpdatedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/DetailsUpdatedToaster';
import phoneNumberUtils from '@/utils/phoneNumber';
import Intercom from '@/Intercom';
import DropdownInput from '@/base-components/DropdownInput';

const HAS_KIDS = 'yes';
const NO_KIDS = 'no';

export default {
  name: 'PersonalInformation',
  components: {
    TextInput: BaseUI.TextInput,
    RiseupButton: BaseUI.RiseupButton,
    DropdownInput,
    AutocompleteInput: BaseUI.AutocompleteInput,
    EmailInput: BaseUI.EmailInput,
  },
  data() {
    return {
      hasKidsInputValue: null,
      isSaving: false,
      email: '',
      emailError: false,
    };
  },
  async created() {
    Segment.trackUserGot('personalInformationEntered', {});
    await this.initDemographicInfo();
    this.hasKidsInputValue = calcHasKidsInputValue(this.numberOfKids);
    await this.loadAllCities();
    this.setEmail(this.activeMember.emailPiiValue);
  },
  computed: {
    ...mapState('session', ['activeMember', 'members']),
    ...mapState('cities', ['cityNames']),
    ...mapState('demographicInfo', ['maritalStatus', 'numberOfKids', 'city']),
    demographicInfo() {
      const data = { maritalStatus: this.maritalStatus, city: this.city, numberOfKids: calcNumberOfKids(this.hasKidsInputValue, this.numberOfKids) };
      return _.pickBy(data, d => !_.isEmpty(d));
    },
    maritalStatusAnswers() {
      return [
        { value: 'single', label: 'רווק.ה' },
        { value: 'married', label: 'בנישואים / בזוגיות' },
        { value: 'divorced', label: 'גרוש.ה' },
        { value: 'other', label: 'אחר' },
      ];
    },
    hasKidsAnswers() {
      return [
        { value: HAS_KIDS, label: 'כן' },
        { value: NO_KIDS, label: 'לא' },
      ];
    },
    HAS_KIDS() {
      return HAS_KIDS;
    },
    isCityValid() {
      return this.city === '' || this.cityNames.includes(this.city);
    },
    disabled() {
      return !(this.isCityValid && !this.emailError && !!this.email && !this.isSaving);
    },
  },
  methods: {
    ...mapActions('session', ['updateActiveMemberEmail']),
    ...mapActions('cities', ['loadAllCities']),
    ...mapActions('demographicInfo', ['initDemographicInfo', 'createDemographicInfo', 'setCity', 'setMaritalStatus', 'setKids']),
    setEmail(value) {
      this.email = value;
    },
    setEmailError(value) {
      this.emailError = value;
    },
    async save() {
      this.isSaving = true;
      await Promise.all([
        this.updateActiveMemberEmail(this.email),
        this.createDemographicInfo(this.demographicInfo),
      ]);
      this.isSaving = false;
      EventBus.$emit('open-toaster', {
        component: DetailsUpdatedToaster,
      });
    },
    setHasKidsInputValue(value) {
      this.hasKidsInputValue = value;
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberUtils.visualizePhoneNumber(phoneNumber);
    },
    openChat() {
      Intercom.show();
    },
  },
};

function calcHasKidsInputValue(kids) {
  if (!_.isNumber(kids)) {
    return null;
  }
  return kids > 0 ? HAS_KIDS : NO_KIDS;
}

function calcNumberOfKids(hasKids, numberOfKids) {
  if (hasKids === NO_KIDS) {
    return '0';
  }
  if (numberOfKids === null) {
    return '';
  }
  return `${numberOfKids}` || '';
}
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.personal-information-container {
  .header {
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    padding-right: 30px;
    border-bottom: 1px solid $riseup_gray_0;
  }
  .demographic-info-header {
    display: flex;
    background-color: $riseup_orange;
    color: $riseup_white;
    line-height: 28px;
    font-size: 20px;
    justify-content: space-between;
    .icon {
      display: flex;
      align-items: center;
    }
    .text {
      padding: 30px 30px 30px 0;
      .title {
        font-weight: bold;
      }
    }
  }
  .personal-information-section {
    padding: 24px;
    .contact-us {
      padding: 17px;
      margin-top: 6px;
      background-color: rgba($riseup_light_blue, 0.5);
      border-radius: 8px;
      display: flex;
      font-size: 14px;
      line-height: 30px;
      .icon {
        height: 27px;
        margin-left: 17px;
      }
      .link {
        margin-right: 4px;
        color: $riseup_blue;
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .demographic-info-section {
    padding: 24px 24px 180px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .save-button {
      margin-top: 32px;
      width: 100%;
    }
  }
  .question-text {
    font-size: 14px;
    margin-bottom: 8px;
    &.disabled {
      color: $riseup_gray_2;
    }
  }
}
</style>
