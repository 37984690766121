<template>
  <div class="dropdown-input-container" :class="{ focus: isFocused, disabled }">
    <div class="top-label" v-if="label">{{ label }}</div>
    <v-select class="dropdown-input" :class="{ empty: !selected }" :value="selected" :components="{ OpenIndicator }" @input="onChange"
              @open="setFocused" @close="setBlurred"
              :searchable="false" :clearable="false" :options="options" :placeholder="placeholder" :disabled="disabled">
    </v-select>
  </div>
</template>

<script>
import _ from 'lodash';
import vSelect from 'vue-select';

// these are the default css for the vue-select component
import 'vue-select/dist/vue-select.css';

export default {
  name: 'DropdownInput',
  components: {
    vSelect,
  },
  props: {
    firstSelected: {
      require: false,
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.getSelection(this.firstSelected),
      OpenIndicator: {
        render: createElement => {
          const arrow = createElement('span', { class: { fa: true, 'fa-caret-left': true } });
          return createElement('div', [arrow]);
        },
      },
      isFocused: false,
    };
  },
  methods: {
    onChange(option) {
      this.selected = option;
      this.$emit('select', option.value);
    },
    getSelection(value) {
      return _.find(this.options, { value }) || null;
    },
    setFocused() {
      this.isFocused = true;
    },
    setBlurred() {
      this.isFocused = false;
    },
  },
  watch: {
    // Sometimes, this component is created before data is properly loaded. So the prop "startValue" starts empty,
    // and then this.selected is started empty as well. We want to "restart" the component when "startValue" becomes available.
    firstSelected(newValue) {
      if (!this.selected) {
        this.selected = this.getSelection(newValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.dropdown-input-container {
  .top-label {
    display: flex;
    color: $riseup_black;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .dropdown-input {
    direction: rtl;
    width: 100%;
    height: 100%;
    font-family: SimplerPro;
    font-weight: 30;
    font-size: 20px;
    border: 1px solid $riseup_gray_2;
    border-radius: 3px;
    color: $riseup_black;
    opacity: 1;
    &:not(.empty) {
      border: 1px solid $riseup_blue;
    }
    &.vs--open {
      outline: none;
      border-radius: 3px 3px 0 0;
      border: 1px solid $riseup_blue;
    }
    .vs__dropdown-toggle {
      height: 40px;
      padding: 0;
      position: sticky;
      display: flex;
      align-items: center;
      box-shadow: none;
      z-index: auto;
      background-color: transparent;
      border: none;

      .vs__selected-options {
        padding: 0;
        > input.vs__search {
          padding: 0 9px;
          &::placeholder {
            color: $riseup_gray_0;
          }
        }
        .vs__selected {
          opacity: 1;
          padding-right: 7px;
        }
      }
      .vs__actions {
        padding-left: 12px;
        .vs__open-indicator {
          font-size: 20px;
        }
      }

    }

    .vs__dropdown-menu {
      position: absolute;
      // I'm missing 2 pixels. 1 on each side. I don't know where they disappeared to.
      left: -1px;
      width: calc(100% + 2px);
      border: 1px solid $riseup_blue;
      border-radius: 0 0 3px 3px;
      padding: 0;
      box-shadow: none;
      text-align: right;
      top: 100%;
      .vs__dropdown-option {
        padding: 0px 9px;
        background-color: $riseup_white;
        color: $riseup_black;
        line-height: 36px;
        font-size: 20px;

        &.vs__dropdown-option--highlight  {
          background: $riseup_gray_0;
        }
      }
    }

    &.vs--open {
      .vs__open-indicator {
        transform: rotate(270deg) scale(1);
      }

      .vs__selected {
        opacity: 1;
      }
    }
  }
}

.dropdown-input-container.focus {
  .label {
    color: $riseup_blue;
  }
}

</style>
