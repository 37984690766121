<template>
  <toaster @close-toaster="close">
    <template v-slot:text>
      <div>
        <span>הפרטים נשמרו בהצלחה!</span>
      </div>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'DetailsUpdatedToaster',
  components: {
    Toaster,
  },
  methods: {
    close() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
