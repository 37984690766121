<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'אנחנו מוכנים!'" :next-button-props="{ name: $options.name }">
    <div class="preparing">
      <div class="title">מעולה!</div>
      <div class="title">זה הזמן לקרוא ל{{ childName }} ולהתחיל את המשחק  🎲</div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapState } from 'vuex';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Preparing',
  components: {
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['childName']),
    buttonText() {
      return `ליצירת משחק דמי הכיס של ${this.childName}`;
    },
  },
};

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.preparing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  .title {
    font-size: 28px;
    font-family: PauzaBold;
  }
}

</style>
