<template>
  <div class="sankey-graph-container">

    <svg width="100%" height="1800">
      <g :transform="`scale(${scaleFactor})`">
        <defs>
          <linearGradient v-for="gradient of gradients" :key="`${gradient.startFill}-${gradient.endFill}`"
                          :id="`${gradient.id}`" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" :style=" { 'stop-color' : gradient.startFill, 'stop-opacity' : 1 }" />
            <stop offset="100%" :style=" { 'stop-color' : gradient.endFill, 'stop-opacity' : 1 }" />
          </linearGradient>
        </defs>
        <g v-for="con of gradientConnections" :key="`${con.from}-${con.to}`">
          <path :d="`M ${con.x1} ${con.y1}
        Q ${con.x1 + 50} ${con.y1}, ${(con.x1 + con.x2) / 2} ${(con.y1 + con.y2) / 2} T ${con.x2} ${con.y2}
        L ${con.x3} ${con.y3}
        Q ${con.x3 - 50} ${con.y3}, ${(con.x3 + con.x4) / 2} ${(con.y3 + con.y4) / 2} T ${con.x4} ${con.y4}
        Z`" :fill="`url(#${con.gradientId})`" opacity="0.3"></path>
        </g>
        <g v-for="block of blocks" :key="block.id">
          <rect :width="block.width" :height="block.height" :x="block.x" :y="block.y" :fill="block.fill" />
          <text
            :x="block.textX"
            :y="block.textY"
            text-anchor="start" font-size="16" font-weight="bold" :fill="block.fill">
            {{ block.name }} {{ hiddenNumbers ? '' : block.formattedAmount }}</text>
        </g>
      </g>

    </svg>
  </div>
</template>

<script>
import _ from 'lodash';

const LAST_COLUMN = 4;
const COLUMNS = 5;
const BLOCK_WIDTH = 45;
const COLUMN_SPACER = 230;

export default {
  name: 'SankeyGraph',
  props: {
    cashflow: { type: Object },
    hiddenNumbers: { type: Boolean, default: false },
  },
  data() {
    return {
      blockWidth: BLOCK_WIDTH,
      verticalSpacer: 50,
      moneyToPixels: 70,
      columnDy: {
        1: 30,
        3: 60,
      },
      columnSpace: COLUMN_SPACER,
      windowWidth: window.innerWidth,
      // the 24 is a "left" margin
      graphWidth: COLUMNS * BLOCK_WIDTH + (COLUMNS - 1) * COLUMN_SPACER + 24,
      padding: { r: 24, t: 24 },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    cashculations() {
      return this.cashflow.getCashculations();
    },
    scaleFactor() {
      return this.windowWidth / this.graphWidth;
    },
    incomeBlocks() {
      return [
        ..._.sortBy(this.cashflow.incomeEnvelopes, envelope => envelope.actualsAmount).map((envelope, index) => {
          return {
            id: `fixed-income-${index}`,
            name: envelope.details.expense,
            connectTo: 'income',
            amount: envelope.actualsAmount * -1,
            column: 0,
            fill: '#97DFAE',
          };
        }),
        {
          id: 'otherIncome',
          name: 'אחר',
          connectTo: 'income',
          amount: -1 * this.cashflow.variableIncomeActualSum,
          column: 0,
          fill: '#97DFAE',
        },
        {
          id: 'income',
          name: 'סה״כ נכנס',
          amount: -1 * this.cashculations.incomeActualSum,
          column: 1,
          fill: '#2EC05D',
        },
      ];
    },
    expenseBlocks() {
      return [
        {
          id: 'all-expenses',
          name: 'סה״כ יצא',
          connectTo: 'income',
          amount: this.cashculations.expenseActualSum,
          column: 2,
          fill: '#FF653F',
          dx: 20,
        },
        {
          id: 'fixed-expenses',
          name: 'קבועות',
          connectTo: 'all-expenses',
          amount: this.cashculations.fixedExpenseEnvelopesActualSum,
          column: 3,
          fill: '#EB73EF',
        },
        ..._getFixedBlocks(this.cashflow),
        {
          id: 'tracking-expenses',
          name: 'במעקב',
          connectTo: 'all-expenses',
          amount: _.sumBy(this.cashflow.envelopes.filter(e => e.type === 'trackingCategory'), e => e.actualsAmount),
          column: 3,
          fill: '#5D7AFD',
        },
        ..._getTrackingCategoryBlocks(this.cashflow),
        {
          id: 'variable-expenses',
          name: 'משתנות',
          connectTo: 'all-expenses',
          amount: this.cashculations.variableActualSum,
          column: 3,
          fill: '#FFCF03',
        },
        ...(this.isCashflowPositive ? [{
          id: 'balance',
          name: 'חסכתי',
          connectTo: 'income',
          amount: this.cashculations.balance * -1,
          column: 2,
          dx: -50,
          dy: 50,
          fill: '#00C070',
        }] : [{
          id: 'balance',
          name: 'חריגה',
          connectTo: 'all-expenses',
          amount: this.cashculations.balance,
          column: 1,
          dx: -50,
          fill: '#FF653F',
        },

        ]),
      ];
    },
    isCashflowPositive() {
      return this.cashculations.balance < 0;
    },
    cashflowBlocks() {
      return [...this.incomeBlocks, ...this.expenseBlocks].filter(({ amount }) => amount > 0);
    },
    blocks() {
      return this.cashflowBlocks.map((block, index) => {
        const otherBlocksInCol = this.cashflowBlocks.slice(0, index).filter(b => b.column === block.column);
        const otherBlocksHeight = _.sumBy(otherBlocksInCol, b => (b.amount / this.moneyToPixels)
          + (b.column !== LAST_COLUMN ? this.verticalSpacer : this.verticalSpacer / 2));
        const positionedBlock = {
          ...block,
          formattedAmount: Math.abs(block.amount).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
          x: this.graphWidth - (this.columnSpace * block.column) + (block.dx || 0) - this.padding.r - this.blockWidth,
          y: otherBlocksHeight + this.verticalSpacer + (this.columnDy[block.column] || 0) + (block.dy || 0) + this.padding.t,
          width: this.blockWidth,
          height: block.amount / this.moneyToPixels,
        };
        const isLastBlockInColumn = _.isEmpty(this.cashflowBlocks.slice(index + 1).filter(b => b.column === block.column));
        return _.extend({
          ..._getTextPosition(positionedBlock, block.column === LAST_COLUMN, isLastBlockInColumn && otherBlocksInCol.length !== 0),
        }, positionedBlock);
      });
    },
    connectionNames() {
      return this.cashflowBlocks.filter(block => !!block.connectTo).map(block => {
        const [{ id: from }, { id: to }] = _.sortBy([this.getBlock(block.id), this.getBlock(block.connectTo)], b => b.column);
        return { to, from };
      });
    },
    connections() {
      return this.connectionNames.map((connection, index) => {
        const startBlock = _.find(this.blocks, b => b.id === connection.from);
        const endBlock = _.find(this.blocks, b => b.id === connection.to);
        const previousStartHeight = this.connectionNames
          .slice(0, index)
          .filter(b => b.from === connection.from)
          .map(({ to }) => _.find(this.blocks, b => b.id === to))
          .reduce((sum, block) => sum + block.height, 0);
        const previousEndHeight = this.connectionNames
          .slice(0, index)
          .filter(b => b.to === connection.to)
          .map(({ from }) => _.find(this.blocks, b => b.id === from))
          .reduce((sum, block) => sum + block.height, 0);
        const amount = _.min([startBlock.height, endBlock.height]);
        return {
          from: endBlock.id,
          to: startBlock.id,
          x1: endBlock.x + this.blockWidth,
          y1: endBlock.y + previousEndHeight,
          x2: startBlock.x,
          y2: startBlock.y + previousStartHeight,
          x3: startBlock.x,
          y3: startBlock.y + previousStartHeight + amount,
          x4: endBlock.x + this.blockWidth,
          y4: endBlock.y + previousEndHeight + amount,
          startFill: endBlock.fill,
          endFill: startBlock.fill,
        };
      });
    },
    gradients() {
      return _.chain(this.connections)
        .uniqBy(connection => `${connection.startFill}-${connection.endFill}`)
        .map(({ startFill, endFill }, index) => { return { startFill, endFill, id: `gradient${index}` }; })
        .value();
    },
    gradientConnections() {
      return _.map(this.connections, connection => {
        const gradient = _.find(this.gradients, g => g.startFill === connection.startFill && g.endFill === connection.endFill);
        return { ...connection, gradientId: gradient.id };
      });
    },
  },
  methods: {
    getBlock(id) {
      return _.find(this.blocks, b => b.id === id);
    },
    onResize() {
      // the 'if' is for Elsa pages where we are in a container
      const pageWrapper = document.querySelector('.sankey-graph-container');
      if (pageWrapper) {
        this.windowWidth = pageWrapper.offsetWidth;
      } else {
        this.windowWidth = window.innerWidth;
      }
    },
  },
};

function _getFixedBlocks(selectedCashflow) {
  return _.chain(selectedCashflow.envelopes)
    .filter(e => e.type === 'fixed' && e.isExpense)
    .groupBy(e => e.details.expense)
    .values()
    .map(envelopes => {
      return {
        id: envelopes[0].id,
        name: envelopes[0].details.expense,
        amount: _.sumBy(envelopes, e => e.actualsAmount),
      };
    })
    .sortBy(e => e.amount)
    .reverse()
    .map(expense => {
      return {
        id: expense.id,
        name: expense.name,
        connectTo: 'fixed-expenses',
        amount: expense.amount,
        column: 4,
        fill: '#EB73EF',
      };
    })
    .value();
}

function _getTrackingCategoryBlocks(selectedCashflow) {
  return _.chain(selectedCashflow.envelopes)
    .filter(e => e.type === 'trackingCategory')
    .groupBy(e => e.details.trackingCategory.name)
    .values()
    .map(envelopes => {
      return {
        id: envelopes[0].details.trackingCategory._id,
        name: envelopes[0].details.trackingCategory.name,
        amount: _.sumBy(envelopes, e => e.actualsAmount),
      };
    })
    .sortBy(e => e.amount)
    .reverse()
    .map(expense => {
      return {
        id: expense.id,
        name: expense.name,
        connectTo: 'tracking-expenses',
        amount: expense.amount,
        column: 4,
        fill: '#5D7AFD',
      };
    })
    .value();
}

function _getTextPosition(block, isLastColumn, isLastInColumn) {
  if (isLastColumn) {
    // text to left of block
    return { textY: block.y + block.height * (2 / 3), textX: block.x - block.width / 3 };
  }
  if (isLastInColumn) {
    // text to bottom of block
    return { textY: block.y + block.height + 20, textX: block.x + (block.width) };
  }
  // text to top of block
  return { textY: block.y - 10, textX: block.x + (block.width) };
}

</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';

.sankey-graph-container {}

</style>
