<template>
  <component :is="pages[currentPage].component" :key="currentPage" :page-index="currentPage"></component>
</template>

<script>
import { mapState } from 'vuex';
import Segment from '@/Segment';
import Parents from './pages/Parents';
import Interest from './pages/Interest';
import Preparing from './pages/Preparing';
import Prediction from './pages/Prediction';
import Intro from './pages/Intro';
import Welcome from './pages/Welcome';
import Money from './pages/Money';
import FirstYear from './pages/FirstYear';
import ThirdYear from './pages/ThirdYear';
import Calculator from './pages/Calculator';
import Thanks from './pages/Thanks';

export default {
  name: 'PocketMoney',
  components: {
    Welcome,
    Parents,
    Interest,
    Prediction,
    Preparing,
    Intro,
    Money,
    FirstYear,
    ThirdYear,
    Calculator,
    Thanks,
  },
  created() {
    Segment.trackUserInteraction('PocketMoneyEntered');
  },
  computed: {
    ...mapState('pocketMoney', ['childName', 'currentPage']),
    pages() {
      return [
        { component: Welcome, buttonText: 'אני רוצה להתחיל!' },
        { component: Intro, buttonText: 'הבא' },
        { component: Parents, buttonText: 'הבא' },
        { component: Interest, buttonText: `ליצירת משחק דמי הכיס של ${this.childName}` },
        { component: Preparing, buttonText: `ליצירת משחק דמי הכיס של ${this.childName}` },
        { component: Money, buttonText: 'הבא' },
        { component: Prediction, buttonText: 'תגלו לי!' },
        { component: FirstYear, buttonText: 'תגלו לי!' },
        { component: ThirdYear, buttonText: 'אפשר לשחק עם המספרים בעצמי?' },
        { component: Calculator, buttonText: 'אני רוצה את הטבלה המלאה!' },
        { component: Thanks, buttonText: 'קחו אותי לקהילת רייזאפ' },
      ];
    },
  },
};

</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.pocket-money-container {}
</style>
