<template>
  <pocket-money-template :page-index="pageIndex" :next-button-props="{ name: $options.name }">
    <div class="select-parent">
      <div class="title">ריבית זה כל הסיפור!</div>
      <div>💸 במקום לתת לילדים שלנו רק דמי כיס, אפשר לתת להם ריבית על הכסף שכבר יש להם!</div>
      <div>כמה אחוזי ריבית שנתית לתת במתנה?</div>
      <dropdown-input :options="options"
                      :first-selected="interestAmount"
                      @select="setInterest"></dropdown-input>
      <div class="weekly-pay">אגב, גם אם יש ל{{ childName }} היום ₪1000, זה ידרוש ממך בשנה הראשונה לתת כ-₪{{ yearlyAmount }} לחודש.</div>
    </div>
  </pocket-money-template>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DropdownInput from '@/base-components/DropdownInput';
import pocketMoneyUtils from '../pocket-money-utils';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Interest',
  components: {
    DropdownInput,
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['interestAmount', 'childName']),
    options() {
      return [
        { value: 5, label: '5%' },
        { value: 6, label: '6%' },
        { value: 7, label: '7%' },
        { value: 8, label: '8%' },
        { value: 9, label: '9%' },
        { value: 10, label: '10%' },
        { value: 11, label: '11%' },
        { value: 12, label: '12% (⭐️ מומלץ)' },
        { value: 13, label: '13%' },
        { value: 14, label: '14%' },
        { value: 15, label: '15%' },
        { value: 16, label: '16%' },
        { value: 17, label: '17%' },
        { value: 18, label: '18%' },
      ];
    },
    yearlyAmount() {
      return Math.round(pocketMoneyUtils.calcInterestAffect(this.interestAmount, 1000, 30));
    },
  },
  methods: {
    ...mapActions('pocketMoney', ['set']),
    setInterest(value) {
      this.set({ prop: 'interestAmount', value });
    },
  },
};

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.select-parent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    font-size: 28px;
    font-family: PauzaBold;
  }
  .image {
    height: 40px;
  }
  .reassurance {
    padding: 8px 0;
  }
  .weekly-pay {
    background-color: $riseup_light_yellow;
    padding: 12px 16px;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.2em;
    color: $riseup_black;
  }
}

</style>
