<template>
  <div class="pocket-money-template-container">
    <div class="page-header" v-if="!isFirst" @click="back" :class="{ game: gameTheme }">
      <div class="back-container" >
        <img class="back" src="/images/nav/left-arrow.svg">
      </div>
    </div>
<!--    <transition name="slide" appear>-->
    <div class="screen-container" :class="{ game: gameTheme }" :style="{ padding }">
      <slot></slot>
    </div>
<!--    </transition>-->
    <div class="next-button-container" :class="{ game: gameTheme }">
      <riseup-button class="next-button" :title="nextButtonText" :action="next" size="slim" :disabled="!canContinue"></riseup-button>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'PocketMoneyTemplate',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    pageIndex: {
      type: Number,
    },
    nextButtonText: {
      type: String,
      default: 'הבא',
    },
    nextButtonProps: {
      type: Object,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    canContinue: {
      type: Boolean,
      default: true,
    },
    gameTheme: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: '20px 40px 40px',
      required: false,
    },
  },
  methods: {
    ...mapActions('pocketMoney', ['nextPage', 'backPage']),
    next() {
      if (this.isLast) {
        window.open('https://bit.ly/DmeiKisExcel', '_blank');
        Segment.trackUserInteraction('PocketMoneyCommunityLinkClicked');
      } else {
        Segment.trackUserInteraction('PocketMoneyNextClicked',
          { pageNumber: this.pageIndex, ...this.nextButtonProps });
        this.$emit('next');
        this.nextPage();
      }
    },
    back() {
      Segment.trackUserInteraction('PocketMoneyBackClicked',
        { pageNumber: this.pageIndex, ...this.nextButtonProps });
      this.backPage();
    },
  },
};

</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.pocket-money-template-container {
  height: 100%;
  font-family: SimplerPro;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .page-header {
    @include responsive-padding;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $riseup_white;
    border-bottom: 1px solid $riseup_gray_0;
    font-size: 16px;
    font-weight: bold;
  }

  .back-container {
    height: 28px;
    margin-right: 8px;
  }
  .screen-container {
    background: $riseup_white;
    width: 100%;
    flex-grow: 1;
    &.game {
      background-color: $riseup_yellow;
    }
  }
  .next-button-container {
    padding: 0 40px 24px;
    width: 100%;
    &.game {
      background-color: $riseup_yellow;
    }
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .4s;
  }
  .slide-enter {
    transform: translateX(100%);
  }
  //.slide-leave-to {
    //transform: translateX(-100%);
  //}
}

</style>
