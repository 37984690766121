<template>
  <pocket-money-template :page-index="pageIndex" :next-button-text="'הבא'"
                         :next-button-props="{ name: $options.name, parent }" :can-continue="canContinue">
    <div class="select-parent">
      <div class="name-prompt">קוראים לי:</div>
      <div class="name">
        <text-input placeholder="שם פרטי" :value="parentFirstName" :on-input="setParentFirstName" label-text=""></text-input>
        <text-input placeholder="שם משפחה" :value="parentFamilyName" :on-input="setParentFamilyName" label-text=""></text-input>
      </div>
      <div class="parent-prompt">ואני</div>
      <dropdown-input :options="options"
                      :first-selected="parent"
                      placeholder=""
                      @select="setParent"></dropdown-input>
      <div class="child-name-prompt">של</div>
      <text-input placeholder="שם הילד.ה" :value="childName" :on-input="setName" label-text=""></text-input>
    </div>
  </pocket-money-template>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import DropdownInput from '@/base-components/DropdownInput';
import PocketMoneyTemplate from './PocketMoneyTemplate';

export default {
  name: 'Intro',
  components: {
    DropdownInput,
    TextInput: BaseUI.TextInput,
    PocketMoneyTemplate,
  },
  props: {
    pageIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('pocketMoney', ['childName', 'parent', 'parentFirstName', 'parentFamilyName']),
    options() {
      return [
        { value: 'אמא', label: 'אמא' },
        { value: 'אבא', label: 'אבא' },
      ];
    },
    canContinue() {
      return this.parentFirstName.length > 1 && this.parentFamilyName.length > 1
        && this.childName.length > 1 && !!this.parent;
    },
  },
  methods: {
    ...mapActions('pocketMoney', ['set']),
    setParent(value) {
      this.set({ prop: 'parent', value });
    },
    setParentFirstName(value) {
      this.set({ prop: 'parentFirstName', value });
    },
    setParentFamilyName(value) {
      this.set({ prop: 'parentFamilyName', value });
    },
    setName(value) {
      this.set({ prop: 'childName', value });
    },
  },
};

</script>

<style scoped lang="scss">

.select-parent {
  display: flex;
  flex-direction: column;
  .title {
    margin-bottom: 8px;
    font-size: 28px;
    font-family: PauzaBold;
  }
  .name-prompt {
    margin: 16px 0 6px;
  }
  .name {
    display: flex;
    gap: 24px;
  }
  .child-name-prompt {
    margin: 16px 0 6px;
  }
  .parent-prompt {
    margin-bottom: 6px;
  }
}

</style>
