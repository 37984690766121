<template>
  <div class="cashflow-flow-container">
    <div class="explanation">
      <span class="link" @click="goToWikipedia">תרשים סנקי</span>
      <span> הוא תרשים נפוץ לתיאור זרימה של חומרים, כסף, אנרגיה, וכמעט כל דבר.
</span>
      <span>הוא מאפשר לראות במבט מהיר את החלוקות השונות ומה היחסים בין כל חלק לשלם.
</span>
      <span>מומלץ לעשות zoom in כדי לראות בצורה ברורה יותר.
</span>
    </div>
    <dropdown-input @select="setBudgetDate"
                    :options="possibleBudgetDates"
                    :first-selected="selectedBudgetDate"
                    label="חודש לצפייה"></dropdown-input>
    <riseup-button class="hide-numbers-button"
                   :action="toggleNumbers"
                   :variant="'primary'"
                   :size="'slim'"
                   :title="showNumbers ? 'הסתר מספרים' : 'הצג מספרים'"/>
    <sankey-graph v-if="selectedCashflow" :cashflow="selectedCashflow" :hidden-numbers="!showNumbers"></sankey-graph>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapState } from 'vuex';
import cashflowHistory from '@/store/utilities/cashflow-history';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import DropdownInput from '@/base-components/DropdownInput';
import dateUtils from '@/utils/dates';
import { DateLocales } from '@riseupil/common-utils';
import SankeyGraph from './SankeyGraph';

export default {
  name: 'CashflowFlow',
  components: {
    SankeyGraph,
    DropdownInput,
    RiseupButton: BaseUI.RiseupButton,
  },
  async created() {
    Segment.trackUserGot('cashflowFlowEntered');
    this.startContentLoading(this.$options.name);
    await this.initBudgets({ budgetDate: cashflowHistory.getCurrentBudgetDate() });
    this.doneContentLoading(this.$options.name);
  },
  data() {
    return {
      selectedBudgetDate: cashflowHistory.getPrevBudgetDate(cashflowHistory.getCurrentBudgetDate()),
      showNumbers: true,
    };
  },
  computed: {
    ...mapState('cashflowView', ['allCashflows']),
    selectedCashflow() {
      const cashflow = _.find(this.allCashflows, ({ budgetDate }) => budgetDate === this.selectedBudgetDate);
      if (cashflow) {
        return reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL);
      }
      return null;
    },
    possibleBudgetDates() {
      return _.chain(this.allCashflows)
        .map(({ budgetDate }) => budgetDate)
        .sortBy()
        .reverse()
        .filter(budgetDate => budgetDate < cashflowHistory.getCurrentBudgetDate())
        .map(budgetDate => {
          return { value: budgetDate, label: dateUtils.getMonthAndYear(budgetDate) };
        })
        .value();
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('cashflowView', ['initBudgets']),
    setBudgetDate(budgetDate) {
      this.selectedBudgetDate = budgetDate;
    },
    toggleNumbers() {
      this.showNumbers = !this.showNumbers;
    },
    goToWikipedia() {
      window.open('https://en.wikipedia.org/wiki/Sankey_diagram', '_blank');
    },
  },
};

</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.cashflow-flow-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .link {
    font-weight: bold;
    color: $riseup_blue;
    cursor: pointer;
    text-decoration: underline;
  }
  .explanation {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
